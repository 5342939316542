document.addEventListener("DOMContentLoaded", function () {
  const carrito = JSON.parse(localStorage.getItem("carrito"));

  if (carrito === null) {
    const carrito = {
      productos: [],
      envio: null,
    };
    localStorage.setItem("carrito", JSON.stringify(carrito));
  }
});
